<template>
  <b-card
    no-body
    class="bg-gradient-warning">
    <b-card-body class="d-flex align-items-center text-white">
      <b-avatar size="45" :src="user.currentCompany.logo" />
      <div class="ml-2 truncate text-white">
        <h4 class="mb-25 font-weight-bolder text-white">
          {{ user.currentCompany.name }}
        </h4>
        <span>{{ user.currentCompany.brand }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  // BCardText,
  BCardBody,
  BAvatar
  // BLink,
  // BButton,
  // BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    // BCardText,
    BCardBody,
    BAvatar
    // BLink,
    // BImg
    // BButton
  },
  directives: {
    Ripple
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    kFormatter
  }
}
</script>
